import React, {useRef} from "react"
import PropTypes from "prop-types";
import ProcessBlock from "../components/ProcessBlock";
import StepsTabs from "../components/StepsTabs";
import StepsAccordion from "../components/StepsAccordion";
import useThemeColor from "../hooks/useThemeColor";
import useOnScreen from "../hooks/useOnScreen";

const DevelopmentSteps = ({ title, steps, agileProcessTitle, agileProcessBlocks }) => {
	const element = useRef(null);
	const wrapper = useRef();
	const isActive = useOnScreen(wrapper, { threshold: 0.7, once: true });
	useThemeColor(element, 'light');

	const getAgileProcessSection = () => {
		if(!agileProcessBlocks.length) {
			return null;
		}

		const reversedAgileProcessBlocks = [
			...agileProcessBlocks
		];

		return (
			<div className="development-steps__process-wrapper" ref={wrapper}>
				{agileProcessTitle && (
					<h3 className="development-steps__process-title">
						{agileProcessTitle}
					</h3>
				)}

				<div className="development-steps__process-blocks">
					<div className="development-steps__process-blocks-wrapper">
						{reversedAgileProcessBlocks.map((blockSettings) => (
							<ProcessBlock
								key={blockSettings.number}
								active={isActive}
								{...blockSettings}
							/>
						))}
					</div>
				</div>
			</div>
		);
	};

	return (
		<section className="development-steps" ref={element}>
			<div className="development-steps__wrapper container" >
				<div
					className="fade-section-title"
					dangerouslySetInnerHTML={{ __html: title }}
				/>

				{Boolean(steps.length) && (
					<>
						<StepsTabs steps={steps} />

						<StepsAccordion steps={steps} />
					</>
				)}

				<div className="development-steps__content">
					{getAgileProcessSection()}
				</div>
			</div>
		</section>
	);
};

DevelopmentSteps.propTypes = {
	title: PropTypes.string.isRequired,
	steps: PropTypes.array.isRequired,
	agileProcessTitle: PropTypes.string,
	agileProcessBlocks: PropTypes.array
};

export default DevelopmentSteps;
