import React from 'react';
import {initialState, indexReducer} from "../reducers/indexReducer";

export const IndexContext = React.createContext({
    state: initialState,
    dispatch: () => null
})

export const IndexProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(indexReducer, initialState);

    return (
        <IndexContext.Provider value={[state, dispatch]}>
            <div className="homepage__wrapper" data-theme={state.isLightTheme ? 'light' : 'dark'}>
                {children}
            </div>
        </IndexContext.Provider>
    );
};
