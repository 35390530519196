import React from 'react';

const IconPlus = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" aria-hidden="true">
            <path clipRule="evenodd" d="M6 0H4V4L0 4V6H4V10H6V6H10V4L6 4V0Z" fill="currentColor"/>
        </svg>
    );
};

export default IconPlus;
