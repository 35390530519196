export const indexReducer = (state, action) => {
    switch (action.type) {
        case "toggle_bg":
            return {
                ...state,
                isLightTheme: action.theme === 'light'
            }
        default:
            return state
    }
}

export const initialState = {
    isLightTheme: false
}
