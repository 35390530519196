import React, {useRef} from "react"
import SectionHeader from "../components/SectionHeader";
import ProjectItem from "../components/ProjectItem";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import useThemeColor from "../hooks/useThemeColor";

const BestProjects = ({ title, subtitle, projects, buttonText, buttonUrl }) => {
	const element = useRef(null);
	useThemeColor(element, 'light');
	if(!projects || !projects?.length) {
		return null;
	}

	return (
		<section className="best-projects" ref={element}>
			<div className="best-projects__wrapper container container--mobile-small">
				<SectionHeader
					subtitle={subtitle}
					title={title}
				/>

				<div className="best-projects__blocks">
					<div className="best-projects__blocks-wrapper">
						{projects.map(project => (
							<ProjectItem
								key={project.id}
								title={project.title}
								slug={project.slug}
								technologies={project.technologies}
								squaredImage={project.acfProject?.squaredImage}
							/>
						))}
					</div>
				</div>

				{buttonText && buttonUrl && (
					<div className="best-projects__link-wrapper">
						<Link
							to={`/projects/category/all/`}
							className="button button--transparent button--large best-projects__link"
						>
							{buttonText}
						</Link>
					</div>
				)}
			</div>
		</section>
	);
};

BestProjects.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	projects: PropTypes.array.isRequired,
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string
}

export default BestProjects;
