import {useState} from "react";
import useSize from "@react-hook/size";

const useCursorFollower = (parentRef) => {
    const [parentWidth, parentHeight] = useSize(parentRef);
    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });

    const handleMouseMove = event => {
        const rect = parentRef.current.getBoundingClientRect();

        setCursorCoords({
            x: event.nativeEvent.clientX - 30 - rect.left,
            y: event.nativeEvent.clientY - 30 - rect.top,
        });
    };

    return {
        cursorCoords,
        parentWidth,
        parentHeight,
        handleMouseMove
    }
}

export default useCursorFollower;
