import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

const Panel = ({ isActive, content, title, index, setActiveTab, activeTab }) => {
	const panelClasses = classNames({
		"steps-accordion__panel": true,
		"steps-accordion__panel--active": isActive
	});

	const changeActiveTab = (event) => {
		setActiveTab(activeTab === index ? -1 : index);

		if(activeTab === index) {
			return;
		}

		const header = document.getElementById("site-header");
		const headerHeight = +header?.offsetHeight - +( header &&window.getComputedStyle(header).getPropertyValue("--offset"));

		setTimeout(() => {
			window.scrollTo({
				top: event.target.offsetTop - headerHeight,
				behavior: "smooth"
			});
		}, 410)
	};

	return (
		<div className={panelClasses}>
			<button type="button" className="steps-accordion__button" onClick={changeActiveTab}>
				{title}
			</button>

			<AnimatePresence initial={false}>
				{isActive && (
					<motion.div
						key="content"
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: {
								opacity: 1,
								height: "auto"
							},
							collapsed: {
								opacity: 0,
								height: 0
							}
						}}
						transition={{
							duration: 0.4,
							ease: [0.1, 0.62, 0.23, 0.98]
						}}
						className="steps-accordion__inner"
					>
						<div className="steps-accordion__content" dangerouslySetInnerHTML={{ __html: content }} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

const StepsAccordion = ({ steps }) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<div className="steps-accordion">
			{steps.map((step, index) => {
				const isActive = index === activeTab;

				return (
					<Panel
						key={index}
						title={step.title}
						content={step.content}
						isActive={isActive}
						index={index}
						setActiveTab={setActiveTab}
						activeTab={activeTab}
					/>
				);
			})}
		</div>
	);
};

StepsAccordion.propTypes = {
	steps: PropTypes.array.isRequired
}

export default StepsAccordion;
