import React from "react"
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {GatsbyImage as Image} from "gatsby-plugin-image";
import classNames from "classnames"
import createValidLink from "../helpers/urlFixer";

const PostCard = ({title, categories, date, link, squaredImage, excerpt, isRow, titleTag}) => {
    const TitleTag = titleTag || "h2";
    const postCategories = categories?.nodes ?? [];

    const postCardClasses = classNames("post-card", {
        "post-card--row": isRow
    });

    const postLink = createValidLink(link);

    return (
        <div className={postCardClasses}>
            <div className="post-card__image-row">
                <div className="post-card__image-wrapper">
                    <Image
                        className="post-card__image"
                        image={squaredImage.localFile?.childImageSharp?.gatsbyImageData}
                        alt={squaredImage.altText || title}
                        title={squaredImage.altText || title}
                    />

                    <Link
                        to={postLink}
                        className={"post-card__image-link"}
                        aria-label={`Look at the article - ${title}`}
                    >
                        <span className={"post-card__image-link-text"}>
                            Read more
                        </span>
                    </Link>
                </div>
            </div>

            <div className="post-card__content">
                <div className="post-card__content-wrapper">
                    <div className="post-card__publish-info">
                        {postCategories.map(({id, name}, index) => {
                            return (
                                <span key={id}>
                                    {
                                        index > 0 && <span className="post-card__publish-info-separator">
											|
										</span>
                                    }

									<span className="post-card__category">
										{name}
									</span>
                                </span>

                            );
                        })}

                        <span className="post-card__publish-info-separator">
							|
						</span>

                        <span className="post-card__date">
							{date}
						</span>
                    </div>

                    <TitleTag className="post-card__title">
                        <Link to={postLink}>
                            {title}
                        </Link>
                    </TitleTag>

                    <div className="post-card__excerpt-wrapper">
                        <p className="post-card__excerpt">
                            {excerpt}
                        </p>
                    </div>

                    <div className="post-card__link-wrapper">
                        <Link to={postLink} className="post-card__link" aria-label={`Read more about ${title}`}>
                            Read more
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

PostCard.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.object,
    date: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    squaredImage: PropTypes.object.isRequired,
    excerpt: PropTypes.string.isRequired,
    isRow: PropTypes.bool
}

export default PostCard;
