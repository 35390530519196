import React, {useRef} from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import ServiceCard from "../components/ServiceCard";
import useThemeColor from "../hooks/useThemeColor";
import {getImage} from "gatsby-plugin-image"
import createValidLink from "../helpers/urlFixer";

const Services = ({
                      title,
                      informationBlockTitle,
                      informationBlockDescription,
                      services,
                      allServicesButtonTitle,
                      allServicesButtonUrl,
                      video,
                      videoPoster
                  }) => {
    const {mediaItemUrl, mimeType} = video ?? {};
    const element = useRef(null);
    useThemeColor(element, 'dark');

    const classes = classNames({
        "home-services": true,
        "home-services--without-video": !mediaItemUrl
    });

    const videoPosterImage = getImage(videoPoster.localFile)

    const allServicesButtonLink = createValidLink(allServicesButtonUrl);

    return (
        <section className={classes} ref={element}>
            {mediaItemUrl && (
                <div className="home-services__video-section">
                    <div className="home-services__video-container">
                        <div className="home-services__video-wrapper">
                            <video
                                className="home-services__video"
                                width="600"
                                height="400"
                                poster={videoPosterImage?.images?.fallback?.src}
                                autoPlay
                                loop
                                muted
                                playsInline
                            >
                                <source src={mediaItemUrl} type={mimeType}/>
                            </video>
                        </div>
                    </div>
                </div>
            )}

            <div className="home-services__wrapper container">
                <div
                    className="home-services__title"
                    dangerouslySetInnerHTML={{__html: title}}
                />
                <div className="home-services__container">
                    {informationBlockTitle && informationBlockDescription && (
                        <div className="home-services__info">
                            <h3 className="home-services__info-title">
                                {informationBlockTitle}
                            </h3>

                            <p>
                                {informationBlockDescription}
                            </p>
                        </div>
                    )}

                    <div className="home-services__list">
                        <div className="home-services__list-row">
                            {services.map(({name, id, acfTechnology}) => (
                                <div className="home-services__list-col" tabIndex={0} key={id}>
                                    <ServiceCard
                                        title={name}
                                        description={acfTechnology.shortDescription}
                                        imageUrl={acfTechnology?.image}
                                        isWhite={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {allServicesButtonTitle && allServicesButtonUrl && (
                        <div className="home-services__link-wrapper">
                            <Link to={allServicesButtonLink}
                                  className="button button--transparent button--large button--white-text home-services__link">
                                {allServicesButtonTitle}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

Services.propTypes = {
    title: PropTypes.string,
    informationBlockTitle: PropTypes.string,
    informationBlockDescription: PropTypes.string,
    services: PropTypes.array
};

export default Services;
