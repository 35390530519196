import React from 'react';
import IconPlus from "../icons/IconPlus";

const CursorFollower = ({ coords, parentWidth, parentHeight }) => {
    return (
        <div
            aria-hidden="true"
            className="cursor-follower"
            style={{
                top: `clamp(0px, ${coords.y}px, ${parentHeight - 60}px)`,
                left: `clamp(0px, ${coords.x}px, ${parentWidth - 60}px)`
            }}
        >
            <IconPlus/>
        </div>
    );
};

export default CursorFollower;
