import React, {useEffect} from "react";
import {IndexContext} from "../providers/IndexProvider";
import useOnScreen from "./useOnScreen";

const useThemeColor = (ref, theme) => {
    const [, dispatch] = React.useContext(IndexContext);
    const isElementVisible = useOnScreen(ref, {rootMargin: "-50% 0px -50% 0px"});

    useEffect(() => {
        if (typeof window !== "undefined" && isElementVisible && dispatch) {
            dispatch({type: "toggle_bg", theme});
        }
    }, [isElementVisible]);
}

export default useThemeColor;
