import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import CursorFollower from "./CursorFollower";
import useCursorFollower from "../hooks/useCursorFollower";
import classNames from "classnames";
import {Link} from "gatsby";
import { GatsbyImage as Image } from "gatsby-plugin-image";

const ProjectItem = ({ title, slug, squaredImage, technologies = {}, path = "projects" }) => {
	const projectRef = useRef();
	const {cursorCoords, parentWidth, parentHeight, handleMouseMove} = useCursorFollower(projectRef);
	const projectTechnologies = technologies?.nodes || [];
	const [isHovered, setIsHovered] = useState(false);
	const image = squaredImage?.localFile?.childImageSharp?.gatsbyImageData;

	if(!image) {
		return null;
	}

	const projectContainerClasses = classNames({
		"project__container": true,
		"project__container--with-content-hover": projectTechnologies?.length
	});

	const projectContentClasses = classNames({
		"project__content": true,
		"project__content--with-hover": projectTechnologies?.length
	});

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<div className={"project"}>
			<div
				className={projectContainerClasses}
				onMouseMove={handleMouseMove}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				ref={projectRef}
			>
				<Link
					className="project__link"
					to={`/${path}/${slug}/`}
					aria-label={`Look at project - ${title}`}
				>
					Learn more
				</Link>

				{
					isHovered ?
					<CursorFollower
						coords={cursorCoords}
						parentWidth={parentWidth}
						parentHeight={parentHeight}
					/>
					: null
				}


				<div className="project__image-wrapper">
					<Image
						className="project__image"
						image={squaredImage.localFile?.childImageSharp.gatsbyImageData}
						alt={squaredImage.altText || title}
						title={squaredImage.altText || title}
					/>
				</div>

				<div className="project__content-wrapper">
					<div className={projectContentClasses}>
						<h2 className="project__title">
							{title}
						</h2>

						{projectTechnologies.map(({acfTechnology = {}, id, name}) => {
							const icon = acfTechnology.icon;

							return (
								<div key={id}>
									<span className={"visually-hidden"}>
										{name}
									</span>

									<div className="project__technology" aria-hidden={"true"}>
										{
											icon && <span
												className="project__technology-icon"
												dangerouslySetInnerHTML={{__html: icon}}
											/>
										}

										{name}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

ProjectItem.propTypes = {
	title: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	squaredImage: PropTypes.object.isRequired,
	technologies: PropTypes.object
}

export default ProjectItem;
