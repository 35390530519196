export default function fadeOut(string) {
    let DOM;

    try {
        DOM = new DOMParser().parseFromString(string, "text/html").querySelector("svg");
    } catch (e) {
        return string;
    }

    const elementsWithId = [...DOM.querySelectorAll("[id]")];

    elementsWithId.forEach(element => {
        const suffix 	= Math.floor(Math.random() * 100000000);
        DOM.innerHTML 	= DOM.innerHTML.replaceAll(element.id, element.id + suffix);
    });

    return DOM.outerHTML;
}