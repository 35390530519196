import React, {useRef} from "react"
import PropTypes from "prop-types";
import SectionHeader from "../components/SectionHeader";
import useThemeColor from "../hooks/useThemeColor";
import {GatsbyImage as Image, getImage} from "gatsby-plugin-image";

const OurClients = ({title, subtitle, clients}) => {
    const element = useRef(null);
    useThemeColor(element, 'dark');

    const OurClient = ({localFile, altText}) => {
        const image = getImage(localFile);

        return (
            <div className="our-clients__block">
                <div className="our-clients__block-wrapper">
                    <Image
                        className="our-clients__block-image-wrapper"
                        image={image}
                        alt={altText || "Our clients"}
                        title={altText || "Our clients"}
                    />
                </div>
            </div>
        )
    }

    return (
        <section className="our-clients" ref={element}>
            <div className="our-clients__wrapper container container--mobile-small">
                <SectionHeader
                    title={title}
                    subtitle={subtitle}
                />

                <div className="our-clients__blocks-wrapper">
                    <div className="our-clients__blocks">
                        {clients.map(({logo}, index) => (
                            <OurClient
                                key={index}
                                {...logo}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

OurClients.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    clients: PropTypes.array
}

export default OurClients;
