import React, {useState, forwardRef, createRef, useRef} from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
import { fadeIn, fadeOut } from "../helpers/fade";

const Step = ({ activeTab, title, number, id, changeActiveTab }) => {
	const buttonClasses = classNames({
		"steps__tab": true,
		"steps__tab--active": activeTab === id
	});

	return (
		<button className={buttonClasses} type="button" onClick={() => changeActiveTab(id)}>
			<span className="steps__tab-number">
				{number}
			</span>

			<span className="steps__tab-title">
				{title}
			</span>
		</button>
	);
};

const Content = forwardRef(({ icon, content, id, activeTab }, ref) => {
	const tabClasses = classNames({
		"steps__tab-content": true,
		"steps__tab-content--active": activeTab === id
	});

	return (
		<div ref={ref} className={tabClasses}>
			{icon && (
				<div className="steps__tab-icon" dangerouslySetInnerHTML={{ __html: icon }} />
			)}

			<div className="steps__tab-rte" dangerouslySetInnerHTML={{ __html: content }}/>
		</div>
	);
});

const StepsTabs = ({ steps }) => {
	const [activeTab, setActiveTab] = useState(0);
	const [references, setReferences] = useState({});
	const [loading, setLoading] = useState(false);
	const section = useRef();

	const getOrCreateRef = (id) => {
		if (!references.hasOwnProperty(id)) {
			setReferences({
				...references,
				[id]: createRef()
			})
		}

		return references[id];
	}

	const changeActiveTab = (id) => {
		if(activeTab === id || loading) {
			return null;
		}

		setLoading(true);

		setActiveTab(id);

		const header = document.getElementById("site-header");
		const headerHeight = +header?.offsetHeight - +( header &&window.getComputedStyle(header).getPropertyValue("--offset"));

		window.scrollTo({
			top: section.current.querySelector(".steps__tab-content--active").offsetTop - headerHeight,
			behavior: "smooth"
		});

		fadeOut(references[activeTab].current, 300, () => {
			fadeIn(references[id].current, 300, () => {
				setLoading(false);
			});
		});
	};

	const tabs 		= [];
	const contents 	= [];

	steps.forEach((step, index) => {
		tabs.push(
			<Step
				key={index}
				id={index}
				changeActiveTab={changeActiveTab}
				activeTab={activeTab}
				title={step.title}
				number={step.number}
			/>
		);

		contents.push(
			<Content
				key={index}
				id={index}
				ref={getOrCreateRef(index)}
				icon={step.icon}
				content={step.content}
				activeTab={activeTab}
			/>
		);
	})

	return (
		<div className="steps" ref={section}>
			<div className="steps__tabs">
				{tabs}
			</div>

			<div className="steps__contents">
				{contents}
			</div>
		</div>
	);
};

StepsTabs.propTypes = {
	steps: PropTypes.array.isRequired
};

export default StepsTabs;
