import React from "react"
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";
import OurClients from "../sections/OurClients";
import BestProjects from "../sections/BestProjects";
import Banner from "../sections/Banner";
import Services from "../sections/Services";
import DevelopmentSteps from "../sections/DevelopmentSteps";
import Posts from "../sections/Posts";
import {IndexProvider} from "../providers/IndexProvider";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const IndexPage = (props) => {
	const { pageContext } = props;
	const { wpPage, allWpPost } = pageContext;
	const { homepage, page_header } = wpPage || {};

	const getSection = ({ fieldGroupName, ...section }, index) => {
		const key = index;

		switch (fieldGroupName) {
			case 'page_Homepage_Sections_OurClients': {
				return (
					<OurClients
						key={key}
						{...section}
					/>
				);
			}

			case 'page_Homepage_Sections_BestProjects': {
				return (
					<BestProjects
						key={key}
						{...section}
					/>
				);
			}

			case 'page_Homepage_Sections_Banner': {
				return (
					<Banner
						key={key}
						{...section}
						pageHeader={page_header}
					/>
				);
			}

			case 'page_Homepage_Sections_DevelopmentSteps': {
				return (
					<DevelopmentSteps
						key={key}
						{...section}
					/>
				);
			}

			case 'page_Homepage_Sections_Services': {
				return (
					<Services
						key={key}
						{...section}
					/>
				);
			}

			case 'page_Homepage_Sections_OurPosts': {
				section.posts = allWpPost.nodes;
				return (
					<Posts
						key={key}
						{...section}
					/>
				);
			}

			default: {
				return null;
			}
		}
	}

	const getSections = () => {
		if (!homepage?.sections) {
			return null;
		}

		return homepage?.sections.map(getSection);
	}

	useBeaconWidget(beaconWidgetIds.GENERAL);

	const pageTitle = homepage?.metaData?.title || "Home";
	const pageDescription = homepage?.metaData?.description || "Welcome to UTD, your premier destination for professional web development services.";
	const pageKeywords = homepage?.metaData?.keywords || "shopify, ecommerce, development, design, support, Theme, APP, Gain, agency, plus ,brands, services, UTD, UTD Development, marketing, email, conversion";
	const showHiddenTitle = homepage?.sections.findIndex(section => {
		return section.fieldGroupName === "page_Homepage_Sections_Banner" && section.title?.length > 0;
	}) === -1;

	const microdata = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"url": "https://utdweb.team",
		"logo": "https://utdweb.team/utd_logo.png",
		"name": "UTD",
		"email": "hello@utdweb.team",
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "Belgium, Antwerpen",
			"postalCode": "2000",
			"streetAddress": "Kapucinessenstraat 6"
		}
	};

	return (
		<DefaultLayout>
			<IndexProvider>
				<DefaultHelmet
					title={pageTitle}
					description={pageDescription}
                    keywords={pageKeywords}
                    microdata={microdata}
                    appendPostfixToTitle={false}
				/>
				{showHiddenTitle && (
					<h1 className={'visually-hidden'}>
						{pageTitle}
					</h1>
				)}
				{getSections()}
			</IndexProvider>
		</DefaultLayout>
	)
};

export default IndexPage;
