import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import IconScrollChevron from "../icons/IconScrollChevron";
import {graphql, Link, useStaticQuery} from "gatsby";
import {initBannerAnimation} from "../vendor/bannerAnimation";
import withFullHeightScreen from "../hocs/withFullHeightScreen";
import withScrollDown from "../hocs/withScrollDown";
import {convertToBgImage} from "gbimage-bridge";
import {motion} from "framer-motion";
import {bannerSubtitleAnimation} from "../settings/generalAnimations";
import useThemeColor from "../hooks/useThemeColor";
import PageHeader from "../components/PageHeader";

const Banner = ({
	title,
	subtitle,
	scrollButtonText,
	buttonFirstTextPart,
	buttonSecondTextPart,
	minHeight,
	scrollDown,
	getReference,
	pageHeader,
}) => {
	const banner = useRef(null);

	const data = useStaticQuery(graphql`
		query {
			borderImage: file(relativePath: { eq: "button-ellipse.png" }) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
				}
			}
		}
	`);

	const headerOnBanner = pageHeader?.position === 'on';
	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';
	const bannerTitleProps = headerOnBanner && pageHeader?.title
		? pageHeader
		: {};

	const bannerTitleMarkup = (
		<PageHeader
			{...bannerTitleProps}
			title={headerOnBanner && pageHeader?.title ? pageHeader?.title : title}
			implemented={true}
			className={"banner__title section-title"}
			isGeneral={headerOnBanner}
		/>
	);

	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader {...pageHeader} isGeneral={true} />
		: null;

	const hasMainButton = !!buttonFirstTextPart || !!buttonFirstTextPart;

	useEffect(() => {
		initBannerAnimation(banner.current);
	}, [banner]);

	const getBanner = ref => {
		banner.current = ref;
		getReference(ref);
	}

	const image = data?.borderImage?.childImageSharp?.gatsbyImageData;
	const backgroundImage = convertToBgImage(image);

	const scrollDownLocal = (event) => {
		event.target.blur();
		scrollDown();
	}


	const element = useRef(null);
	useThemeColor(element, 'dark');

	const styles = minHeight ? { minHeight: minHeight } : null;

	return (
		<>
			<section
				className="banner"
				ref={getBanner}
				style={styles}
			>
				<div className="banner__wrapper container" ref={element}>
					<div className="banner__content">
						<div className="banner__header">
							{bannerTitleMarkup}

							{subtitle && (
								<motion.h2
									className="banner__subtitle"
									{...bannerSubtitleAnimation}
									initial={{
										y: "0",
										opacity: 0
									}}
								>
									{subtitle}
								</motion.h2>
							)}

							{hasMainButton && (
								<Link to="/contact-us/" className="button banner__button banner__button--mobile" aria-label="Go to contact page">
								<span>
									{`${buttonFirstTextPart} ${buttonSecondTextPart}`}
								</span>
								</Link>
							)}
						</div>
					</div>

					{hasMainButton && (
						<Link
							to="/contact-us/"
							className="round-button banner__button banner__button--desktop"
							style={{
								"--border-image": `url("${backgroundImage?.fluid?.src}")`
							}}
						>
							{buttonFirstTextPart && (
								<span className="banner__button-text banner__button-text--first">
								{buttonFirstTextPart}
							</span>
							)}

							{buttonSecondTextPart && (
								<span className="banner__button-text banner__button-text--second">
								{buttonSecondTextPart}
							</span>
							)}
						</Link>
					)}
					{scrollButtonText && (
						<button
							type="button"
							className="scroll-button"
							onClick={scrollDownLocal}
						>
							<IconScrollChevron /> {scrollButtonText}
						</button>
					)}
				</div>

			</section>

			{titleBelowBannerMarkup}
		</>
	);
};

Banner.propTypes =  {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	scrollButtonText: PropTypes.string,
	buttonFirstTextPart: PropTypes.string,
	buttonSecondTextPart: PropTypes.string,
	minHeight: PropTypes.string.isRequired,
	scrollDown: PropTypes.func.isRequired,
	getReference: PropTypes.func.isRequired,
};

export default withFullHeightScreen(
	withScrollDown(
		Banner
	)
);
