import React, {useRef} from "react"
import PropTypes from "prop-types";
import {Link} from "gatsby";
import SectionHeader from "../components/SectionHeader";
import PostCard from "../components/PostCard";
import useThemeColor from "../hooks/useThemeColor";
import createValidLink from "../helpers/urlFixer";

const Posts = ({ subtitle, title, posts, buttonText, buttonUrl }) => {
	const element = useRef(null);
	useThemeColor(element, 'light');

	const buttonLink = createValidLink(buttonUrl);

	return (
		<section className="posts" ref={element}>
			<div className="container container--mobile-small posts__container">
				<SectionHeader
					subtitle={subtitle}
					title={title}
				/>

				<div className="posts__row">
					{posts.map(post => {
						const postLink = createValidLink(`/blog-posts${post.link}`);
						return (
							<div
								className="posts__item"
								key={post.id}
							>
								<PostCard
									titleTag={"h3"}
									title={post.title}
									categories={post.categories}
									date={post.date}
									link={postLink}
									squaredImage={post.acfPost?.squaredImage}
									excerpt={post.acfPost?.excerpt}
								/>
							</div>
						)
					})}
				</div>

				{buttonText && buttonUrl && (
					<div className="posts__link-wrapper">
						<Link
							to={buttonLink}
							className="button button--transparent button--large posts__link"
						>
							{buttonText}
						</Link>
					</div>
				)}
			</div>
		</section>
	);
}

Posts.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	posts: PropTypes.array.isRequired,
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string
}

export default Posts;

